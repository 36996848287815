.fadeInRight {
  animation-name: fadeInRightCustom!important; }

.fadeInLeft {
  animation-name: fadeInLeftCustom!important; }


@keyframes fadeInLeftCustom {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0); }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightCustom {
  from {
    opacity: 0;
    transform: translate3d(50px, 0, 0); }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }


.projectsTitle {
  animation-name: projectsTitle; }

@keyframes projectsTitle {
  from {
    color: rgba(white, 0);
    left: 50%; }

  to {
    left: 10%;
    color: rgba(white, 0.2); } }
