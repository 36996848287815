.projects {
  position: relative;

  .fit-image {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    display: none;
    top: 20px;
    background-image: url('./../media/fit.png');
    z-index: 10;
    background-size: cover;
    @include media($media-xs) {
      display: block; } }

  .image-popup {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    background: black;
    img {
      width: 100%; } }

  .image-popup-close {
    width: 30px;
    height: 30px;
    right: 20px;
    top: 20px;
    position: absolute;
    div {
      right: 0;
      width: 100%;
      height: 1px;
      background: white;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &:nth-child(1) {
        transform: rotate(45deg); }
      &:nth-child(2) {
        transform: rotate(-45deg); } } }


  &[data-slides='first'] {
    .fp-prev {
      display: none; } }

  &[data-slides='last'] {
    .fp-next {
      display: none; } }


  .slide-loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10;
    background: black;
    width: 100%;
    height: 100%; }

  .fp-controlArrow {
    height: 100%;
    top: 0;
    position: absolute;
    cursor: pointer;
    background-position: 70% calc(50% + 55px);
    background-repeat: no-repeat;
    background-size: 23px;
    border: unset!important;

    &.fp-next {
      right: 0;
      background-image: url('./../media/arrow.png');
      width: 20%;
      max-width: 200px; }

    &.fp-prev {
      background-position: 70% calc(50% - 55px);
      left: 0;
      transform: rotate(180deg);
      width: 20%;
      background-image: url('./../media/arrow.png');
      max-width: 200px; } }

  .wrapper {
    flex: 1 0;
    flex-direction: column;
    display: flex;
    width: 100%; }


  .first-slide {
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .project-scene {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 5;
      position: absolute; }

    .main-title {
      position: relative;
      z-index: 4;
      width: 100%;
      text-align: center;
      font-weight: 800;
      font-size: 120px;
      color: white;

      @include media($media-xs) {
        font-size: 60px; } } }

  .descr-slide {
    width: 70%;

    .descr {
      z-index: 3;
      position: relative;
      max-width: 350px;
      line-height: 30px;
      @include media(xs) {
        max-width: unset; } }

    .title {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -30px;
      font-weight: 600;
      font-size: 100px;
      color: rgba(white, 0.2); } }

  .descr-wrapper {
    overflow: hidden;
    &>div {
      position: relative; } }

  .fp-tableCell {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex; }

  .image-slide {
    width: 100%;
    flex: 1 0;
    // display: flex
    justify-content: center;
    overflow: hidden;
    align-items: center;
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    img {
      // margin: 0 auto
      // left: 50%
      // top: 50%
      // transform: translate(-50%, -50%)
      // position: absolute
      // min-height: 100%
      // display: inherit
 } }      // min-width: 100%

  .pagination-list {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    padding-bottom: 35px;
    width: 100%;
    .item {
      height: 15px;
      width: 15px;
      justify-content: center;
      align-items: flex-end;
      cursor: pointer;

      display: flex;
      &.active {
        &>div {
          height: 20px;
          background: $red; } }

      &>div {
        transition: all .3s;
        background: #fff;
        width: 4px;
        height: 4px; } } }


  .project {
    flex: 1 0;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    .content {
      width: 100%;
      align-items: center;
      flex:  1 0;
      z-index: 5;
      display: flex; }



    &-images, &-all-images {
      display: none; }

    &-scene {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0; } } }

