.loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  top: 0;
  background: $red;
  width: 100%;
  height: 100%;
  position: {}
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  &>div {
    background-image: url('./../media/logo_icon.svg');
    width: 150px;
    height: 150px;
    background-position: 50% 50%;
    // background-size: contain
    background-size: 400%;
    background-repeat: no-repeat;
    animation: fly 3s infinite linear;
    animation: fly 3s infinite linear; } }

.scene {
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  background: black;
  right: 0;
  top: 0;
  background-image: url('./../media/homepage_bg.png');
  background-size: contain;
  background-position: 0 0;
  background-repeat: no-repeat; }

.logo {
  height: 160px;
  width: 160px;
  background: $red;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;
  background-image: url('./../media/icon.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  &.mobile {
    width: 60px;
    height: 60px;
    background-size: 25px; } }

.home {
  &>.fp-tableCell {
    position: relative;
    background: $grey;
    background-image: url('./../media/homepage_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 40px 30px; }

  .title {
    animation-fill-mode: forwards;
    font-weight: 600;
    color: $red;
    flex: 1 0;
    z-index: 2;
    align-items: center;
    display: flex;
    font-size: 60px;
    width: 50%;
    span {
      background: transparent;
      transition:  all .3s;
      color: white;
      &:hover {
        background: $red;
        cursor: pointer; } } }

  .contacts {
    width: 50%;
    font-size: 20px;
    z-index: 2;
    pointer-events: none;
    .wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 450px;
      a {
        pointer-events: auto;
        color: $red;
        animation-duration: 3s;
        font-weight: 500; } } }


  @include media-not-land($media-md) {
    .title {
      text-align: left;
      width: 100%;
      justify-content: center; }

    .contacts {
      width: 100%;
      // display: flex
      .wrapper {
        margin: 0 auto; }
 } } }      // justify-content: center


@include media($media-xs) {
  .logo {
    width: 60px;
    height: 60px;
    background-size: 25px; }


  .home {
    .title {
        text-align: left;
        font-size: 10vmin; } } }


@include media-not-land($media-xs) {
  .home {
    .contacts {
      // font-size: 15px
      .wrapper {
        flex-direction: column;
        .phone {
          margin-top: 10px; } } } } }



@keyframes fly {
  0% {
      transform: rotate(0); }
  100% {
      transform: rotate(360deg); } }
