.thanks {
  background: $red;
  color: #fff;
  &>div {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; }

  a {
    color: #fff; }

  .title {
    // padding-top: 50%
    font-weight: 600;
    text-align: center;
    font-size: 180px; }


  .phone, .email {
    padding-bottom: 20px;
    font-size: 32px;
    text-align: center; }


  .footer {
    justify-self: flex-end;
    width: calc(100% - 60px);
    position: absolute;
    left: 30px;
    bottom: 20px;
    display: flex;
    flex-direction: column;

    .subtitle {
      text-align: center;
      width: 100%;
      margin-bottom: 40px;
      font-size: 24px; }

    .contacts {
      font-size: 32px;
      display: flex;
      justify-content: center; }

    .back {
      font-size: 16px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding-top: 40px;
      align-items: flex-end;
      position: relative;
      &:before {
        display: block;
        content: '';
        width: 200px;
        height: 200px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        background: url('./../media/backbg.png');
        background-size: 100%;
        background-position: 0 0;
        background-repeat: no-repeat; } } }



  @include media($media-md) {
    .title {
      font-size: 15vw; } }

  @include media($media-sm) {
    .footer {
      .contacts {
        font-size: 14px; } }

    .email, .phone {
      font-size: 14px; } }

  @include media($media-xs) {
    .footer {
      .back {
        padding-top: 0;
        font-size: 14px;
        &:before {
          top: -30px; } }

      .subtitle {
        margin-bottom: 20px;
        font-size: 16px; } } } }
