.burger {
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 40px;
  align-items: flex-end;
  right: 30px;
  z-index: 10;
  cursor: pointer;

  &>div {
    background: #464646;
    position: relative;
    height: 2px;
    transition: all .3s;

    &:nth-child(1) {
      top: 0;
      width: 80%; }
    &:nth-child(2) {
      width: 100%; }
    &:nth-child(3) {
      bottom: 0;
      width: 80%; } }

  &.active {
    &>div {
      background: white;
      width: 100%;
      &:nth-child(2) {
        opacity: 0; }

      &:nth-child(1) {
        position: relative;
        top: calc(50%);
        transform: rotate(-45deg); }

      &:nth-child(3) {
        position: relative;
        bottom: calc(50% - 2px);
        transform: rotate(45deg); } } } }


.navbar {
  z-index: 9;
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  transition: all 1s;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  // background-image: url('./../media/menu_bg.png')
  background-position: 100% 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;

  &:before {
    content: '';
    display: block;
    top: 0;

    height: 200vw;
    position: absolute;
    width: 100vh;
    background: black;
    transition: all .3s;
    left: 100%;
    transform: rotate(75.7deg);
    transform-origin: left top; }

  &:after {
    content: '';
    display: block;
    bottom: 0;
    z-index: 3;


    height: 200vw;
    position: absolute;
    width: 100vh;
    background: black;
    transition: all .3s;
    right : 100%;
    transform: rotate(75.7deg);
    transform-origin: bottom right; }


  &.closed {
    pointer-events: none;
    .menu {
      opacity: 0;

      transition: all .3s; }

    &:before {
      transform: rotate(0); }

    &:after {
      transform: rotate(0); }

    .navbar-logo {
      opacity: 0;
      transition: all .3s; } }

  .navbar-logo {
    height: 160px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: url('./../media/logo-expanded.png');
    background-repeat: no-repeat;
    background-position: 56px 50%;
    background-size: 200px 50px;
    z-index: 8;
    opacity: 1;
    transition: all 1s;
    &.mobile {
      height: 60px;
      background-size: 100px 25px;
      background-position: 15px 50%; } }


  .menu {
    width: 50%;
    z-index: 8;
    position: relative;
    justify-content: center;
    flex-direction: column;
    display: flex;
    font-weight: 600;
    font-size: 20px;
    opacity: 1;
    transition: all 1.3s;

    .item {
      align-items: center;
      color: $red;
      cursor: pointer;
      display: flex;
      margin-top: 20px;

      &:before {
        display: block;
        opacity: 0;
        content: '';
        min-width: 50px;
        height: 1px;
        background: #fff;
        margin-right: 20px;
        display: inline-block; }

      &.active {
          color: #fff;
          &:before {
            opacity: 1; } } } }

  @include media($media-xs) {
    .menu {
      font-size: 18px;
      width: 80%;

      .item {
        margin-top: 15px; } }

    .navbar-logo {
      height: 60px;
      background-size: 100px 25px;
      background-position: 15px 50%; } } }
