.about {
  background-color: #dfdfdf;
  background-image: url('./../media/About_me_background.jpg');
  color: #000;
  background-position: 0 0;
  background-size: auto 100%;
  background-repeat: no-repeat;

  &>div {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }

  .wrapper {
    flex-wrap: wrap;
    display: flex;
    width: 50%; }

  .title {
    line-height: 30px;
    width: 100%;
    font-size: 40px;
    font-weight: 600;
    color: #4b4b4b; }

  .subtitle {
    letter-spacing: 2px;
    color: #4b4b4b;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    text-transform: uppercase; }

  .subtitle {
    width: 100%; }

  .description {
    width: 100%;
    // padding: 200px 0
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;

    &-title {
      font-size: 22px;
      font-weight: 600;
      color: $red;
      margin-bottom: 40px; }

    .item {
      width: 50%;
      max-width: 300px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      &:nth-child(1n + 2) {
        margin-left: 20px; }
      .text {
        &>div {
          &:nth-child(1n + 2) {
            margin-top: 25px; } } }

      .button {
        margin-top: 70px;
        a {
          display: inline-block;
          color: white;
          padding: 18px 25px;
          background: $red; } } } }

  @include media-not-land($media-ipad) {
    .wrapper {
      width: 100%;
      .subtitle, .title {
        padding-left: 40px; }
      .description {
        justify-content: center;
        text-align: left; } } }

  @include media($media-sm) {
    background-image: unset;
    .title, .subtitle {
      text-align: left;
      max-width: unset!important;
      width: 100%; } }

  @include media($media-xs) {
    .wrapper {
      justify-content: flex-end;
      padding: 50px 0 50px 50px; }

    .title {
      max-width: 230px; }

    .description {
      margin-top: 0;
      flex-direction: column;

      .item {
        margin-top: 40px;
        margin-left: unset!important;
        max-width: 100%;
        width: 100%;
        .button {
          margin-top: 40px; } } } } }


.iScrollIndicator {
  opacity: 0; }

.mdb {
  @include media-up($media-xs) {
    display: none; } }

.mdn {
  @include media($media-xs) {
    display: none; } }
