.pagination {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 4px;
  display: flex;
  flex-direction: column;
  z-index: 5;

  .item {
    width: 100%;
    flex: 1 0;
    background: #464646;
    cursor: pointer;
    cursor: pointer;
    margin: 1px 0;
    transition: all .3s;
    &.active {
      background: $red; } } }



