@font-face {
    font-family: 'Replica';
    src: url('Replica-Regular.eot');
    src: url('Replica-Regular.eot?#iefix') format('embedded-opentype'),
        url('Replica-Regular.woff2') format('woff2'),
        url('Replica-Regular.woff') format('woff'),
        url('Replica-Regular.ttf') format('truetype'),
        url('Replica-Regular.svg#Replica-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Replica';
    src: url('Replica-Bold.eot');
    src: url('Replica-Bold.eot?#iefix') format('embedded-opentype'),
        url('Replica-Bold.woff2') format('woff2'),
        url('Replica-Bold.woff') format('woff'),
        url('Replica-Bold.ttf') format('truetype'),
        url('Replica-Bold.svg#Replica-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Replica';
    src: url('Replica-Light.eot');
    src: url('Replica-Light.eot?#iefix') format('embedded-opentype'),
        url('Replica-Light.woff2') format('woff2'),
        url('Replica-Light.woff') format('woff'),
        url('Replica-Light.ttf') format('truetype'),
        url('Replica-Light.svg#Replica-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

