@import "./../../node_modules/animate.css/animate.css";
@import './variables.sass';
@import './animations.sass';
@import './home.sass';
@import './burger.sass';
@import './thank.sass';
@import './about.sass';
@import './pagination.sass';
@import './projects.sass';
@import './fonts/replica/stylesheet.css';

body {
  padding: 0;
  margin: 0;
  background: black;
  color: white;
  font-family: 'Replica', sans-serif; }

*, *:before, *:after {
  box-sizing: border-box; }


a {
  text-decoration: unset; }

.section {
  overflow: hidden; }
