$red: #ff0000;
$grey: #d1cfcf;

$media-xlg: 1399.8px;
$media-lg: 1199.8px;
$media-md: 991.8px;
$media-sm: 767.8px;
$media-xs: 574.8px;
$media-xxs: 320px;

$media-ipad: 1024px;

@mixin media($width) {
  @media(max-width: $width) {
    @content; }

  @media(max-height: $width) {
    @content; } }

@mixin media-not-land($width) {
  @media(max-width: $width) {
    @content; } }

@mixin media-up($width) {
  @media(min-width: $width) {
    @content; } }

